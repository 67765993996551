
html,
body{
  background-color:#c8ced3;
  overflow-x: hidden;
  overflow-y: hidden;
  margin:0;
  padding:0;
}

.mark{
  background-color: grey;
}

.wrapper {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  perspective: 2px;
}

.section {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.section h1 {
  font-size: 800%;
  font-weight: 700;
  line-height: 120%;
}
.section h2 {
  font-size: 300%;
  line-height: 150%;
}
.section h3 {
  font-size: 250%;
  font-weight: 300;
  line-height: 150%;
}
.section h4 {
  font-size: 200%;
  font-weight: 300;
  line-height: 150%;
}

.section-headline {
  margin-top: 5%;
  margin-left: 50px;
  margin-right: 50px;
  justify-content: flex-start;
}

.section-body{
  margin-top: 50px;
  align-items: stretch;
  justify-content: center;
  margin-bottom: 10%;
}

.section-body-lessbottommargin{
  margin-top: 50px;
  align-items: stretch;
  justify-content: center;
  margin-bottom: 5%;
}


.section-footer{
  position: relative;
  margin-left: 50px;
  margin-top: 20px;
  align-items: stretch;
  justify-content: right;
  text-align: right;
  margin-bottom: 90px;
}

.static-black {
  background: black;
  color: white;
}

.static-white {
  background: white;
  color: black;
}

.static-white-footer {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: right;
  background: white;
  color: black;
}


.hero {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-image: url('../assets/img/brand/pricekit-bee-1.jpg');
    background-attachment: fixed;
    background-size: cover;
}

.hero-text {
  margin-top:15%;
  margin-left: 50px;
  color: white;
  text-shadow: 0 0 5px #000;
  justify-content: flex-start;
}

.hero-text h1{
  font-size: 800%;
}
.hero-text h3{
  font-size: 300%;
}

.hero-button{
  margin-top: 20px;
}

.closer {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: right;
  background-image: url('../assets/img/brand/pricekit-bee-2.jpg');
  background-attachment: fixed;
  background-size: cover;
}

.modal-backdrop {
  opacity: 0.5;
}

.modal.fade.in {
  opacity: 1;
}

.modal.fade.in .modal-dialog {
  transform: translate(0,0);
}

.fade{
   opacity: 1;
}

.page-header-text {
  margin-top:20px;
  margin-left: 50px;
  color: black;
  justify-content: flex-start;
}

.page-header-text h1{
  font-size: 400%;
}
.page-header-text h4{
  font-size: 200%;
}