// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';
  
  .nav .nav-content {
    max-width: 900px;
    padding: 0rem 3rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  
  .nav-item {
    display: inline;
    margin-left: 2rem;
    color: #333;
  }
  
  .nav-item:hover {
    color: rgb(12, 12, 12);
    cursor: pointer;
  }

  .nav-item > .active {
    border-bottom: 1px solid rgb(12, 12, 12);
}

.middle-center {
  align-items: center;
  justify-content: center;
}

.survey-toast{
  background-color: rgba(0, 0, 0, 0.8)
}

.survey-toast-text{
  font-size: 8pt;
  color: white;
}