
html,
body{
  background-color:#c8ced3;
  overflow-x: hidden;
  overflow-y: hidden;
  margin:0;
  padding:0;
}

.mark{
  background-color: grey;
}

.wrapper {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.tour {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.tour-question-header {
  position: relative;
  margin-left: 30px;
  margin-top: 60px;
}

.tour h1 {
  font-size: 800%;
  font-weight: 700;
  line-height: 120%;
}
.tour h2 {
  font-size: 300%;
  line-height: 150%;
}
.tour h3 {
  font-size: 250%;
  font-weight: 300;
  line-height: 150%;
}
.tour h4 {
  font-size: 200%;
  font-weight: 300;
  line-height: 150%;
}
.tour h5 {
  font-size: 150%;
  font-weight: 300;
  line-height: 150%;
}

.tour-headline {
  margin-top: 5%;
  margin-left: 50px;
  margin-right: 50px;
  justify-content: flex-start;
}

.tour-body{
  margin-top: 50px;
  margin-left: 50px;
  align-items: stretch;
  justify-content: center;
  margin-bottom: 10%;
  margin-right:50px;
}

.tour-card-listgroup {
  margin-top: 10px;
  font-size: 100%;
  font-weight: 300;
}

.static-black {
  background: black;
  color: white;
}

.static-white {
  background: white;
  color: black;
}


.tour-hero {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    background-image: url('../assets/img/brand/pricekit-bee-4.jpg');

    background-size: cover;
}

.tour-hero-text {
  margin-top: 20%;
  margin-left: 50px;
  margin-right: 100px;
  color: black;
  justify-content: flex-start;
}

.tour-hero-text h1{
  font-size: 800%;
  font-weight: 700;
}
.tour-hero-text h3{
  font-size: 300%;
}

.tour-hero-button{
  margin-top: 20px;
}

.modal-backdrop {
  opacity: 0.5;
}

.modal.fade.in {
  opacity: 1;
}

.modal.fade.in .modal-dialog {
  transform: translate(0,0);
}

.fade{
   opacity: 1;
}

.annotation-helper{
  margin-top:10px;
  color:#20a8d8;
}

@media screen and (max-width: 1200px) {
  .annotation-helper p {
    display: none;
  }
}