
.annotation-helper p {
  margin-top:10px;
  color:#20a8d8;
}

@media screen and (max-width: 1200px) {
  .annotation-helper p {
    color:#204949;
  }
}