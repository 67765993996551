
///colors
$white:#fff;
$primary: #20a8d8;
$secondary: #c8ced3;
$danger: #f86c6b;
$info: #63c2d3;
$light: #f0f3f5;
$dark: #2f353a;
$success: #4dbd74;
$warning: #ffc107;
$gray-base: #181b1e;
$gray-100:  #f0f3f5;
$gray-200:  #e4e7ea;
$gray-300:  #c8ced3;
$gray-400:  #acb4bc;
$gray-500:  #8f9ba6;
$gray-600:  #73818f;
$gray-700:  #5c6873;
$gray-800:  #2f353a;
$gray-900:  #23282c;
$black:     #000;


$mainbackground:  #f86c6b;
$mainaccentcolor: #63c2d3;


///fonts
$primaryfont: 'Roboto';
$secondaryfont: 'Raleway';
$defaultfontsize: 20px;

.survey-loader{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}

/// surveywrapper - background color - main background

.survey-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: flex-start;
  align-items: stretch;
  height: 100vh;
  padding-top: 75px;
  padding-bottom: 25px;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: $primaryfont;
  font-size: $defaultfontsize;
  font-weight: 400;

  h1{
    font-weight: 600;
    font-size: 2em;
  }

  h2{
    font-weight: 200;
    font-size: 1.5em;
  }

  h3{
    font-weight: 400;
    font-size: 1.25em;
  }

  h4{
    font-weight: 200;
    font-size: 0.8em;
  }

  p {
    font-weight: 400;
  }


}

/// page level props

.survey-page-headings {
  font-family: $secondaryfont;
  font-size: $defaultfontsize;
  font-weight: 400;
  margin-top: 25px;
  margin-bottom: 15px;
  
  h2{
    font-weight: 600;
  }

  h4{
    font-family: $primaryfont;
    font-weight: 300;
  }
}

.survey-page-card-body{
  min-height: 70vh;

}

.survey-page-navigation{
  display: flex;
  font-size: 2em;
  font-size: 500;

  .btn-xl {
    padding: 5px 10px;
    font-size: 16px;
    border-radius: 20px;
    width:100px;    //Specify your width here
}

.btn-xxl {
  padding: 5px 10px;
  font-size: 16px;
  border-radius: 20px;
  width:200px;    //Specify your width here
}

}

.survey-page-footer{
  display: flex;
  font-size: .6em;
  font-weight: 200;
  margin-bottom: 25px;
}

/// question props

.survey-question-headings {
  font-family: $secondaryfont;
  font-size: $defaultfontsize;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 25px;
  margin-left: 15px;
  
  h2{
    font-weight: 600;
  }

  h4{
    font-weight: 400;
  }
}

.survey-row-of-cards{
display: flex;
flex-direction: row;
align-items: stretch; 
justify-content: space-evenly; 
flex-wrap: wrap;
}


/// Survey - FORMS

.survey-form-card{
  flex-grow: 1;
  margin-left: 5px;
  margin-right: 5px;

  label{
    font-weight:300;
    font-size: 1.2em
  }

  .custom-control-label::before ,.custom-control-label::after{width:14px; height:14px}

}

.survey-form-card-label{
  font-weight: 300;
  margin-top: 25px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;

}

.survey-form-card-text{
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 30px;

}

.survey-form-card-header{

  font-family: $secondaryfont;
  font-size: $defaultfontsize;
  font-weight: 400;
}

.survey-form-card-footer{

  font-family: $primaryfont;
  font-size: 0.75em;
  font-weight: 300;
  background-color: transparent;
}

/// survey CARDS

.survey-choice-card{
  flex-grow: 1;
  margin-left: 5px;
  margin-right: 5px;

  label{
    font-size: 1em;
    font-weight: 200;
  }

  .custom-control-label::before ,.custom-control-label::after{width:14px; height:14px}

}

.survey-choice-card-footer{

  font-family: $primaryfont;
  font-size: .75em;
  font-weight: 300;

  .btn-xl {
    margin-top: 10px;
    background-color: #728391BF;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 20px;
    width:80px;    //Specify your width here
}

}

.survey-feature-list{

  font-family: $primaryfont;
  font-weight: 200;
}

/// survey LIST

.survey-list-table{

  font-size: 1em;
  font-weight: 300;

  table {
    table-layout: fixed;
    word-wrap: break-word;

    td{
      padding:"5px";
    }

    thead{
      font-size: 1em;
      font-weight: 400;
      padding: 5px;

      tr{

        th{
          font-size: 1em;
          font-weight: 200;
          color:#fff;
          margin-left: 5px;
          margin-right: 5px;
        
        }
    }
    }

  }

  label{
    font-size: 0.8em;
    font-weight: 200;
  }

  .custom-control-label::before ,.custom-control-label::after{width:12px; height:12px}

  tr:hover {background-color: rgba(32,168,216,0.1);}

}

.survey-list-features{

  font-size: 0.8em;
  font-weight: 200;
  margin-top: 5px;
  margin-left: 5px
}

.survey-list-col-header{

  font-size: 1em;
  font-weight: 400;
}


/// survey CONTENT

.survey-content-jumbotron2{
  
  h1{
    font-weight: 600;
    font-size: 3em;
    font-family: $secondaryfont;
    line-height: 100%;
  }

  h4{
    font-family: $primaryfont;
    font-size: 1.25em;
    font-weight: 200;
  }

  p{
    font-family: $primaryfont;
    font-size: 0.8em;
    font-weight: 300;
  }

///  hr{
///    border-top: 1px dotted #8c8b8b;
///  }


}
